import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "CLINICSアプリのリニューアルの裏側（Android編）",
  "date": "2022-06-30T02:53:07.000Z",
  "slug": "entry/2022/06/30/115307",
  "tags": [],
  "hero": "./2022_06_30.png",
  "heroAlt": "CLINICSアプリのリニューアルの裏側（Android編）"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`はじめまして。医療プラットフォーム本部プロダクト開発室エンジニアの小形（`}<a parentName="p" {...{
        "href": "https://twitter.com/ogaclejapan"
      }}>{`@ogaclejapan`}</a>{`）です。
普段は、オンライン診療・服薬指導アプリ「`}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`CLINICS`}</a>{`」の開発を担当しています。
近々、転職の入社エントリーも会社の`}<a parentName="p" {...{
        "href": "https://note.com/medley/"
      }}>{`公式 note`}</a>{`に公開されますので、ぜひ読んでみてください。`}</p>
    <p>{`さて、昨年 12 月に CLINICS アプリは`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20211207.html"
      }}>{`UI のフルリニューアル`}</a>{`を行いました。
少し経ちましたが、「`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2022/02/15/180002"
      }}>{`CLINICS アプリのリニューアルの裏側(iOS 編)`}</a>{`」に続く、Android 編ということで裏話を書いていきます。`}</p>
    <h1>{`コミットグラフから振り返るアプリ開発史`}</h1>
    <p>{`リニューアルを終えた直近のコミットグラフから CLINICS の Android 版アプリの歴史を振り返ると、大きく 4 つの時代を超えてきました。`}</p>
    <ol>
      <li parentName="ol">{`立ち上げ期：2016/7 -`}</li>
      <li parentName="ol">{`閑散期：2018/4 -`}</li>
      <li parentName="ol">{`再始動期：2020/5 -`}</li>
      <li parentName="ol">{`リニューアル期： 2021/5 - 2021/12`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1e1c27a78e00a2782042a19f2dad0d06/5b6ee/2022_06_30_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9klEQVQY00XLPU/DMBAA0Pz/DcE/QIiViaELS4XERwoJTezc+dyQNrEdJ74maQVFDKhFAumtL9rkGROx1r86ws1qyV7/2Xa6tTqBGuqpqLZpUhOOgT/2n4eISQWAgCcAgSBYCO4fu8JbkhXn1SDeB13sk8x43h2+vqNBaweCFY5KDaiYkB1yi6eJwSE76CzRZgfrvayGWKxv0vlrjX7cRmvIZulMiQWJl5VMrMq9kb2TfMxFcMXQojHiVjzeUzlXdPF8fRZfni+u7sqnqOltXOXSaunLZY3UlGHsAvvWm3HiaWLvjQ8ua1XaqGUFqSlSqx7KN93XP/kdBxp8iTgpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "直近のコミットグラフ",
            "title": "直近のコミットグラフ",
            "src": "/static/1e1c27a78e00a2782042a19f2dad0d06/c1b63/2022_06_30_01.png",
            "srcSet": ["/static/1e1c27a78e00a2782042a19f2dad0d06/5a46d/2022_06_30_01.png 300w", "/static/1e1c27a78e00a2782042a19f2dad0d06/0a47e/2022_06_30_01.png 600w", "/static/1e1c27a78e00a2782042a19f2dad0d06/c1b63/2022_06_30_01.png 1200w", "/static/1e1c27a78e00a2782042a19f2dad0d06/5b6ee/2022_06_30_01.png 1672w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`1. 立ち上げ期`}</h2>
    <p>{`記念すべき PR#1 は、2016 年 7 月でした。すでにコミットグラフの表示期間外なのであまり詳しく追えていませんが、1〜2 名がメインで関わりつつ、他のエンジニアの方のコミットもちらほらとありました。
私のようにネイティブアプリ開発を中心に経験してきた人は、まだ社内に少ないですが、フロントエンドからバックエンドまで幅広くできる人がとても多いのも納得です。`}</p>
    <p>{`実装は SmartUI と名がつけられた`}<del parentName="p">{`アンチパターンの`}</del>{`ように、ネットワーク通信から画面の表示制御まで単一のファイル内にすべて書かれていました。
アプリ経験者不在の中で開発を続けてきたことに尊敬の念を抱きつつも、リニューアル開発で全面的にコードを書き直すまで色々なツラみがありました...。R.I.P. FatActivity 🛐`}</p>
    <h2>{`2. 閑散期`}</h2>
    <p>{`2018 年から約 2 年ほど開発がほぼ止まっていました。もともとオンライン診察機能のために開発された経緯があり、この期間は電子カルテのシステム開発にリソースを注力していたようです。`}</p>
    <h2>{`3. 再始動期`}</h2>
    <p>{`2020 年 5 月から再びアプリ開発は活発になり、オンライン服薬指導機能を提供する調剤薬局窓口支援システム「`}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`Pharms`}</a>{`」と連携する開発などが 1〜2 名で行われていました。`}</p>
    <p>{`2020 年度通期の`}<a parentName="p" {...{
        "href": "https://ssl4.eir-parts.net/doc/4480/ir_material_for_fiscal_ym/95472/00.pdf"
      }}>{`決算説明資料`}</a>{`を読んでみると、患者（≒ アプリ）を中心にプロダクトラインナップを増やしていく医療 PF 事業投資の図が初登場していました。
この事業計画に沿って、Pharms 連携やお薬手帳機能など患者向けアプリを強化する動きが加速し、2016 年から引き継がれてきた UI デザインや拡張性をゼロベースで見直すべく、翌年のリニューアル開発へ繋がっていきます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d21d306db4479dd85dda7b8b81259a31/ac307/2022_06_30_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAABYlAAAWJQFJUiTwAAACAElEQVQoz42STWsUQRCG97d6FwRPxpzE6EXjTXMRLwp686xghKwYISDCKurOzmYz+zEz/TnTPVXdPT3dsjORaIjgQ1NQBW/VW909cs5lWZYkCSFkcXo6mUwIpTHGEEL8BwEwcBEAR957KSVjrAGQUhZlqbUOIXjvu67z3rdtO8Qh3dat9Q10zo1CCFmWrdfr5XKZJAljbOiFiMYYpZTgHACEEIhQVVVd18ZaNMZ7P4oxdl23jd5rrRHRWnul58HLn5WtGACUUlrr2Ww2nU5XqxX0eO+dc1JKpVStdZqmm80aEeu6BoBzMed8s9lwzhERAAxu4UI2gM7aoiwZIfl8vs7zRbaiZEtd1+fiy5fZe1YNMFFt08GxkAVl82WutP7Ldui5JP785evh0XGMMaPLPM/RtrP52eTbD9UgoDHWXTF56NF6v/fwyZt34xijcWaapN9/plo3Z9mqpDJJF4DmKnE/VjfNrTsP3r7/MFQoFwVhZVnOplNEW5Ssbf2FOIRgra2VYlwg4vNXr69d3zkcf+ofMljrKK8orxrAdcEAzMXO4+OTx09fHDx7eW//YHfv0e27+zd379/Y2Tv6eBJjrGqtAc+yFRMSjZ3NF5QL17a/n0rI9DTLC5IXNC8IoZxQXhKK/WLOtX74p/3peobtRuemQxf/A++9Nda51lqnm+YXk6cWWH/YIW0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "A.医療PFにおける事業投資 - 2020年度通期決算説明資料 p32",
            "title": "A.医療PFにおける事業投資 - 2020年度通期決算説明資料 p32",
            "src": "/static/d21d306db4479dd85dda7b8b81259a31/c1b63/2022_06_30_02.png",
            "srcSet": ["/static/d21d306db4479dd85dda7b8b81259a31/5a46d/2022_06_30_02.png 300w", "/static/d21d306db4479dd85dda7b8b81259a31/0a47e/2022_06_30_02.png 600w", "/static/d21d306db4479dd85dda7b8b81259a31/c1b63/2022_06_30_02.png 1200w", "/static/d21d306db4479dd85dda7b8b81259a31/d61c2/2022_06_30_02.png 1800w", "/static/d21d306db4479dd85dda7b8b81259a31/ac307/2022_06_30_02.png 2058w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`余談ですが、今年の 2 月に歯科業務支援システム「`}<a parentName="p" {...{
        "href": "https://dentis-cloud.com/"
      }}>{`Dentis`}</a>{`」をリリースして、4 月からアプリとの連携も試験的に開始しています。
リニューアル開発が完了していたからこそ、新規プロダクトへの連携にもスムーズに対応できました。`}</p>
    <h2>{`4. リニューアル期`}</h2>
    <p>{`リニューアル開発は、2021 年 5 月下旬頃から本格的に実装を開始し、同年 12 月に無事リリースすることができました。
当初、Android アプリ開発メンバーの頭数が足りておらず、私と協力会社の社外エンジニアのみで進めていく状況でしたが、6 月途中からアプリ開発経験のある社内エンジニアが 1 名異動してきてくれたおかげでピーク時を乗り切れました。`}</p>
    <p>{`リニューアル後のアプリ内モジュール構成と技術スタックはこんな感じです。
今年の 3 月に`}<a parentName="p" {...{
        "href": "https://android-developers-jp.googleblog.com/2022/03/rebuilding-our-guide-to-app-architecture.html"
      }}>{`刷新された公式のアプリアーキテクチャガイド`}</a>{`に近く、最低限データアクセス部分を分離したレイヤー構成としました。
モノクロではない色付きの技術スタックが今回新たに導入したものになります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bf09aefdd1a849eb151571949663bbf2/b6e50/2022_06_30.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAACQklEQVQozwE3Asj9AFnbj/qc6Kv/meSp/pLfpf6T4Kb+near/pDmpv5L2Yn7SdiI/0/ai2////sT3/bCHubtwyPi6sIk9frKG+Prwifh6cIn9PnIGunwxyXt9tEHAFzakf+w6rv/rOW4/6bdtP+k3bP/sui8/6Dms/9M14n8RdaF/17ckoHx7tWr3+3OnNznyrnf68yy6PLTlNnlycnf6sy45/LUl+Tvz6/x+NEZAGDmnfyr8eH8rvLj/KDj1fyk6Nn8sfPm/J7v1fxP44/6T+KO/2jnon/f6f+nx+Lux83l8sjX7vua0en1qcvj787a8fuS0er2utbt94IATP8AAC6IZrw+kHHFO45vw02bfMZElXbEPY9wwzeLbMMuiWXCNYxqylWbgV/y7vKx5unppufp6qrn6Omy7fXUl+Hry8ng6snG8PnWj+Pty77p8c1+ACY9S/ejrJj4qrOf+Jmjk/icpZP4srmj+JGckfh4hYL1pK2d/zZMWn329fSp4OHitOjp6ZLe4eLG3uHfveDj4Lzp6ued4OPhyevt52f/7v8AAB0/TP+Ysrf/ZIGG/3OPlv+ctrv/d5KX/yVGTv8iQ0z9JEVM/ytJVYL59/er3+Hiuujp6pTd4OHG6Onqmdve4Mbm5+it5OXnnd7g4sfu7u9EABQ2Rvxwjp//P19w/lNxgv5ykKH+T25//gEkNf4IKjv7GjpK/yA/TnL///8c1dnbMOnq6iTk5eYv8/PxIN7h4THk5uYs7e7tIN7h4jrt7ewYMmGMqprkCI0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "アプリ内モジュール構成と技術スタック",
            "title": "アプリ内モジュール構成と技術スタック",
            "src": "/static/bf09aefdd1a849eb151571949663bbf2/c1b63/2022_06_30.png",
            "srcSet": ["/static/bf09aefdd1a849eb151571949663bbf2/5a46d/2022_06_30.png 300w", "/static/bf09aefdd1a849eb151571949663bbf2/0a47e/2022_06_30.png 600w", "/static/bf09aefdd1a849eb151571949663bbf2/c1b63/2022_06_30.png 1200w", "/static/bf09aefdd1a849eb151571949663bbf2/d61c2/2022_06_30.png 1800w", "/static/bf09aefdd1a849eb151571949663bbf2/b6e50/2022_06_30.png 1862w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`次章からリニューアル開発で取り組んだことについて、詳しく書いていきます。`}</p>
    <h1>{`リニューアル開発で取り組んだこと`}</h1>
    <p>{`リニューアル開発のアプリをリードしていく役割で意識的に取り組んだことは次の 3 つです。`}</p>
    <ul>
      <li parentName="ul">{`未来像を描く`}</li>
      <li parentName="ul">{`PoC を作成する`}</li>
      <li parentName="ul">{`期間と機能の折り合いをつける`}</li>
    </ul>
    <h2>{`未来像を描く`}</h2>
    <p>{`当たり前のことですが、リニューアル開発が最終的な自分たちのゴールではありません。
この点を意識しながら、最初に `}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2022/02/15/180002"
      }}>{`iOS アプリをリード`}</a>{`したエンジニアの世嘉良さんとメドレーに合ったアプリ開発のあるべき姿を一緒に描きました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/73c965d86b6a49e307470ca7d7c80d2d/f8544/2022_06_30_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQoz02R3U7DMAyF9/4PhLjgFnExJBCIjcEQGoOOdevaJk0cO/5BbTfUc5cvPj52MjOzkLEhMDURsUGiWlMgZZsoqxCTmanqSGZmttg/3ayu6qZWUdXejwzXr1fz9QPGRDmPsHY/t8vi+9dzRhE9m3X3mVf3oetEZOwqCPByV2/eMbNeIEe/P1RN16nwSHrzF1SbdEqYmM+04/Qctku38c6llGRIrrKbV+u3XeXblpnPZvTH1JaIOCwyJFOi5odCG2PsH2IcJ7bdoXBgMk220KiriGjw9rQ/rh/R9eP872LB5+NOLzUX80Q6JHcUn9vPkAWREJMM1RuoFh+vFh2yjP8ys0mnydiQdqtTC0iiKuP1iUPbFIbxP3wWQthut0QEAOkihAhV2ZyOZVnGGBERADJSZgbEnHNRFN77P2KZCX7mySnQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "202X未来像",
            "title": "202X未来像",
            "src": "/static/73c965d86b6a49e307470ca7d7c80d2d/c1b63/2022_06_30_03.png",
            "srcSet": ["/static/73c965d86b6a49e307470ca7d7c80d2d/5a46d/2022_06_30_03.png 300w", "/static/73c965d86b6a49e307470ca7d7c80d2d/0a47e/2022_06_30_03.png 600w", "/static/73c965d86b6a49e307470ca7d7c80d2d/c1b63/2022_06_30_03.png 1200w", "/static/73c965d86b6a49e307470ca7d7c80d2d/d61c2/2022_06_30_03.png 1800w", "/static/73c965d86b6a49e307470ca7d7c80d2d/f8544/2022_06_30_03.png 1811w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`ざっくりとした図ですが、202X 未来像をメンバーと共有できたのは良かったと思っています。
事業的な観点や技術的な視点から自分たちがこの先に向かいたい方向性が明確になりました。
私が入社したときからすでに Web、iOS、Android の 3 つのプラットフォームを横断するチーム体制だったこともあり、今後も横断しながら効率的に取り組める開発スタイルを目指しています。`}</p>
    <p>{`最終的には、未来と現在を結ぶ通過点として今回のリニューアルを据え、期間や体制面などから実現可能な範囲を話し合って決めました。
当時、私は入社 2 ヶ月目でコードの理解がまだ浅かったので、認識ズレのギャップを埋めることにもこの図が役立ちました。`}</p>
    <h2>{`PoC を作成する`}</h2>
    <p>{`開発するメンバー間の経験差が気になるときや実現性に不安が残るときは、PoC（Proof of Concept）モデルとして 1 つ以上の主要な実装パターンを事前に検証しておくと不確実性を低減できます。
今回は自分がリードする立場であり、Android 開発経験が豊富なメンバーが揃ってガンガンと進めていける感じではなかったので、入念に事前準備してリニューアル開発に臨みました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f65a73420773242ddeb0e39aca71d74d/191e2/2022_06_30_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "103.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAACh0lEQVQ4y5VUy3LbMAzU/39Oe+kP5NJj20nTQ2K9KZEUJVLv53YAO7bs1J5GMxiCErVcAAt47WDRjQ517dD3PeqmgSlLiCyDEBmkUtBFwX4qMhSmhHUOwzCAnm3brsx7+irw9EUgSivkSvEPUmkcghBRnCBJUuRSIRUCSSr4G1nbdWfA/epFLwuCXz2WBVjXFcuyYls39t9vXZfluD8Z7WnFjtkJFd7P79+ghh5/RAHftIjcyBZUPSI7IrID1AS2fABEvyHrN4juYrQP6Fy3wBOxD1nW8IVEokq8xhkOqUQsDaK8QKxKBJnGW6pQNAOaeUMzbcd1Z25c0M4bPGJaliUObweEQYjn598IgwCF1kiTBHEcwxQGlWsvoX14Lu89cq2rOelZlnMRRJZDac3VzaVEludQSnORyIwpURQGZVVhpuSfirJuJ4amrOAH4RFEF5BSsRljWC4ESvIhsDCKWVIESPLp+v7MkmVDDMdxgnOWGdGttNZ1g67rHoSJKx2++9562qiyQZpJZkYsnKsvstlJaLuVyi6PV4CZ2/Aa5ojjCEoXyHMJax2apkVVWVQ3+foX+BVg7hbEmUGSJJy3OEk4dwuJeMdy3xX7sM+A5BNoYSzKynLIlDsCoH5dTqxu83UX8J2hrIEg1RAiRZoKlhGFTWxpYNyG+oDh8QDljXRY1zWzm+eZ3xPDeV7uhnufoZ0QpQpaKR5fJJthHBmYLrgHdhdQ2QFRKqG1ZkDqBjKq8u3sewh4DtnUrEPq667redg6R7JpPleU4yHA1i36YTznj3JHzKZp+vDjf4Vs6glF1aBtGh4E1NeVtcyS2H66KH6x4MeLjzDweVCQdN4OPq/LTYc8AvwLqbxdZx1idukAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "PoCモデルを検証したPR",
            "title": "PoCモデルを検証したPR",
            "src": "/static/f65a73420773242ddeb0e39aca71d74d/c1b63/2022_06_30_04.png",
            "srcSet": ["/static/f65a73420773242ddeb0e39aca71d74d/5a46d/2022_06_30_04.png 300w", "/static/f65a73420773242ddeb0e39aca71d74d/0a47e/2022_06_30_04.png 600w", "/static/f65a73420773242ddeb0e39aca71d74d/c1b63/2022_06_30_04.png 1200w", "/static/f65a73420773242ddeb0e39aca71d74d/d61c2/2022_06_30_04.png 1800w", "/static/f65a73420773242ddeb0e39aca71d74d/191e2/2022_06_30_04.png 1836w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`PoC で検証できた実装方針は、メンバーにも既存 1 画面の書き換えを試してもらい、実際に PR をレビューして本番環境へ投入しています。
5 年前のコードベースが多く残るアプリをどのように移行させるか検証は少し大変でしたが、この事前準備の甲斐あって、リニューアル開発の期間中は技術的な要素でハマることなくタスクに集中できました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f221bd34c1670db7e6b08d2b8e4fd117/191e2/2022_06_30_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0klEQVQY002P226EMAxE9///cZdrLmA7thMKYdniFvrS8zTS6Gg0DzN7vw9VLcuimjVnUSVKROnKoiJ6tWV5H0etdV3XbdvM7DztcZ52mgGg8/75fDVN2/eD82GaZkSKcRJRAIhxYpEZwDnvfai1mt2ymSFiYiYiH0LXDzNAWZZ93ymlcrPd2D+uZTP7WtfROQDsuq7t+n4YQ4jO+dF553yI8dW0IUREYpbEnEupW/3+k+u+AyJRSsyiyiKUrs/TPAMgizBLzhnwyjmXWvfj+PzKPy8oGeyopdYdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "PoCモデルを検証したPRコメント",
            "title": "PoCモデルを検証したPRコメント",
            "src": "/static/f221bd34c1670db7e6b08d2b8e4fd117/c1b63/2022_06_30_05.png",
            "srcSet": ["/static/f221bd34c1670db7e6b08d2b8e4fd117/5a46d/2022_06_30_05.png 300w", "/static/f221bd34c1670db7e6b08d2b8e4fd117/0a47e/2022_06_30_05.png 600w", "/static/f221bd34c1670db7e6b08d2b8e4fd117/c1b63/2022_06_30_05.png 1200w", "/static/f221bd34c1670db7e6b08d2b8e4fd117/d61c2/2022_06_30_05.png 1800w", "/static/f221bd34c1670db7e6b08d2b8e4fd117/191e2/2022_06_30_05.png 1836w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`期間と機能の折り合いをつける`}</h2>
    <p>{`PoC モデルを作成して技術的な実現性への不安を解消できましたが、リニューアル開発を進めていく期間と仕様面で、まだいくつかの不安要素は残っている状況でした。`}</p>
    <ul>
      <li parentName="ul">{`私自身、入社 2 ヶ月目で既存の業務ロジックは一部しか把握できてない`}<br />{`
⇒ 　業務の仕様を理解しながら新しい画面へ書き換えていくので時間かかるかも…🧑‍💻`}</li>
      <li parentName="ul">{`要素しか決まっていない画面が多数あり、画面デザインが並行して進んでいた`}<br />{`
⇒ 　どのタイミングで要素を取得するべきなのか、デザイン次第で変わりそう…🎨`}</li>
      <li parentName="ul">{`協力会社から一緒に手伝ってくれる社外エンジニアの実力値が未知数だった`}<br />{`
⇒ 　軽く感覚は掴んでもらったものの、良しなにタスクを進められるだろうか…🧗‍♀️`}</li>
    </ul>
    <p>{`いくら経験を積んだエンジニアでも不確実性を多く含むタスクの工数は正確に見積もれません。
そこで、リニューアルに関するアプリの開発定例で「もし想定する期間に間に合わなかったときにどうするか？」という議題を提起しました。
当たり前の話になっちゃいますが、うまくいかないときに取る行動を全員が共通認識しておくことは大切だと思っています。結論の候補は次のどちらかになるのではないでしょうか。`}</p>
    <ol>
      <li parentName="ol">{`リリース日を延ばして計画したリニューアルのタスクはすべてやりきる`}</li>
      <li parentName="ol">{`一部機能を落としてリリース日はずらさない`}</li>
    </ol>
    <p>{`今回のリニューアル開発では、後者にあたるリリース日を死守する結論になりました。
ただし、一部の機能を落とすのではなく、既存の旧画面をスタイル調整してでも機能は出したいという要望があり、この点を設計やスケジュールのタスク優先度に折り込みました。`}</p>
    <p>{`12 月に無事リリースできた Android アプリの裏側には、事業サイドとのこのような合意がありました。
実際、予定外のことも起きて一部の機能はリニューアル後に回しましたが、取る行動を決めておいたことで終盤あたふたすることなく、安定したものをリリースできました。`}</p>
    <h1>{`これから`}</h1>
    <p>{`ここまでお読みいただきありがとうございました。同じような立場でこれからリニューアル開発を進めていく方のヒントになったら幸いです。
コードは 1 行も載せていませんが、Android アプリ開発者なら図の構成でなんとなく想像が付くと思い、今回は進め方にフォーカスしてみました。`}</p>
    <p>{`さて、患者アプリはリニューアルが無事完了し、モダンな技術スタックで効率良く開発できる状態になりましたが、まだまだ技術的な視点で取り組みたいことはたくさんあります。`}</p>
    <ul>
      <li parentName="ul">{`Kotlin Multiplatform／Kotlin Multiplatform Mobile の活用`}<sup parentName="li" {...{
          "id": "fnref-1"
        }}><a parentName="sup" {...{
            "href": "#fn-1",
            "className": "footnote-ref"
          }}>{`1`}</a></sup><br parentName="li"></br>
        {`⇒ 　 iOS、Android（可能なら Web も）のロジック統合など生産性を上げる`}</li>
      <li parentName="ul">{`宣言的 UI への移行（iOS はすでに SwiftUI）`}<br parentName="li"></br>
        {`⇒ 　 UI アーキテクチャ構成やコンポーネント化など UI の共通感覚を高める`}</li>
    </ul>
    <p>{`一方、患者アプリのビジネス視点では、オンライン診療を主軸とした予約・フォローアップの体験向上が 1 つの役目になります。
私自身は、「オンライン診療の実施に当たっての基本理念`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{`」に沿った成長路線がより良い患者体験につながると考えています。`}</p>
    <blockquote>
      <small>Ⅳ オンライン診療の実施に当たっての基本理念</small><br /><br /><small>① 患者の日常生活の情報も得ることにより、医療の質のさらなる向上に結び付けていくこと</small><br /><small>② 医療を必要とする患者に対して、医療に対するアクセシビリティ（アクセスの容易性）を確保し、よりよい医療を得られる機会を増やすこと</small><br /><small>③ 患者が治療に能動的に参画することにより、治療の効果を最大化すること</small><br /><br />
    </blockquote>
    <p>{`この基本理念の 3 項目をアプリが満たすべき姿としたのが次の 2 つの状態です。`}</p>
    <ul>
      <li parentName="ul">{`医療を身近に行動できる状態（基本理念の ① と ③ に該当）`}<br parentName="li"></br>
        {`⇒ 　「探す」「知る」「伝える」といった行動手順の簡素化`}</li>
      <li parentName="ul">{`必要とする人が適切に使えている状態（基本理念の ② に該当）`}<br parentName="li"></br>
        {`⇒ 　アクセシビリティへの配慮、分かりやすい UI（≒ ユニバーサルデザイン）`}</li>
    </ul>
    <p>{`このような領域に興味のあるエンジニアやデザイナーさんがおりましたら、ぜひお近くのメドレーへ。
医療ヘルスケアの未来を一緒につくりませんか？`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>
    <p>{`最後までお読みいただきありがとうございました。`}</p>
    <p><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` `}<small>{`Flutter や React Native といった選択肢もありますが、現時点では外部 SDK 含めた既存コードとの親和性や時間的な制約から段階的に移行できる技術を重視しています`}</small><br parentName="p"></br>{`
`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` `}<small>{`厚生労働省が公開する「`}<a parentName="p" {...{
          "href": "https://www.mhlw.go.jp/content/000889114.pdf"
        }}>{`オンライン診察の適切な実施に関する指針 10 ページ目`}</a>{`」より抜粋 `}</small></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      